import useAxios from "@routes/hooks/use-axios";
import { endpoints, replaceParams } from "@utils/axios";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as z from "zod";
import { SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { paths } from "@routes/paths";
import { ManagePatientContext } from "@pages/manage-patient/view/ManagePatient";
import Button from "@components/button/button";
import { ButtonVariant } from "@components/button/button";
import { toast } from "react-toastify";
import { OutlinedButton } from "@components/button";

import { getUserPersistedOnLocalStorage } from "@authentication/context/jwt/utils";
import { generateForm } from "./InitialADLQuestions";
import {
  ADLQuestions,
  Convert,
  ObservationScoreParameter,
} from "./types/ADLQuestions";
import { Disclosure, RadioGroup, Tab } from "@headlessui/react";
import {
  CheckCircleIcon,
  CheckIcon,
  ChevronUpIcon,
} from "@heroicons/react/20/solid";
import { ADLAnswers } from "./types/ADLAnswers";
import ADLAssessmentGraph from "./ADLGraphs";
import { ADLCategory } from "./types/ADLcategory";
import moment from "moment";

type ADLAssessmentComponentProps = {
  patientId: string;
  masterAssessmentId: string;
  assessmentId: string;
  postSubmitCallback: () => void;
};

export default function ADLAssessmentComponent(
  props: ADLAssessmentComponentProps
) {
  const userData = getUserPersistedOnLocalStorage();

  const [loading, setLoading] = React.useState(true);
  const [isDisabled, setIsDisabled] = React.useState(false);
  const [intensity, setIntensity] = useState(0);
  const [adlQuestions, setAdlQuestions] = useState<ADLQuestions | null>(null);

  const [adlCategories, setADLCategories] = useState<ADLCategory[]>([]);

  const navigate = useNavigate();

  const axios = useAxios();

  const patientId = useContext(ManagePatientContext);

  const [lastUpdateAt, setLastUpdatedAt] = React.useState("");

  const {
    register,
    handleSubmit,
    watch,
    reset,
    control,
    setValue,
    getValues,
    setError,
    formState: { errors },
  } = useForm();

  function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(" ");
  }

  const setValueFunction = (index: number, e: any, questIndex: number) => {
    // Update the adlQuestions state with the new answer
    const updatedAdlQuestions = { ...adlQuestions };

    // Check if adlQuestions is defined before accessing it
    if (updatedAdlQuestions) {
      // Check if categoryScoreDetails is defined before accessing it
      if (updatedAdlQuestions.categoryScoreDetails) {
        // Check if the specified index and questIndex are within bounds
        if (
          index < updatedAdlQuestions.categoryScoreDetails.length &&
          questIndex <
            updatedAdlQuestions.categoryScoreDetails[index].questionScore!
              .length
        ) {
          // Update the answer
          updatedAdlQuestions.categoryScoreDetails[index].questionScore![
            questIndex
          ].answer = {
            score: e?.score,
            text: e?.text,
          };
          if (
            updatedAdlQuestions.categoryScoreDetails[index].scoreAnswered === -1
          )
            updatedAdlQuestions.categoryScoreDetails[index].scoreAnswered = 0;
          // Update the state
          setAdlQuestions(updatedAdlQuestions);
        } else {
          console.error(
            "Index out of bounds or questionScore is undefined at specified index."
          );
        }
      } else {
        console.error("categoryScoreDetails is undefined.");
      }
    } else {
      console.error("adlQuestions is undefined.");
    }
  };

  React.useEffect(() => {
    const initializeAssessment = async () => {
      if (props.assessmentId !== "000000000000000000000000") {
        try {
          const paramsMap = new Map<string, string>([
            ["mid", props.masterAssessmentId],
          ]);
          const filteredEndpoint = replaceParams(
            endpoints.assessment.getADLAssessment,
            paramsMap
          );
          const response = await axios.get(filteredEndpoint);
          const data = response.data["data"];
          if (data.categoryScoreDetails === null) setAdlQuestions(null);
          else {
            const converted = Convert.toADLQuestions(JSON.stringify(data));

            if (converted.categoryScoreDetails) {
              const categoriesMap: ADLCategory[] =
                converted.categoryScoreDetails
                  .filter((val) => typeof val.categoryCode === "string") // Filter out undefined values
                  .map((val) => {
                    return {
                      category: val.category!,
                      categoryCode: val.categoryCode!,
                    };
                  });
              setADLCategories(categoriesMap);
              //console.log(categoriesMap);
            }

            //console.log(converted);

            setAdlQuestions(converted);

            setLastUpdatedAt(data["audit"]["lastUpdatedAt"]);

            //reset(convertedBranchList);

            setLoading(false);
          }
        } catch (error) {
          toast.error(error.message);

          console.error("Error fetching Muscle Wasting assessment:", error);

          setLoading(false);
        }
      } else {
        try {
          const paramsMap = new Map<string, string>([
            ["id", userData?.entity_id!],
          ]);
          const filteredEndpoint = replaceParams(
            endpoints.assessment.getADLQuestions,
            paramsMap
          );
          const response = await axios.get(filteredEndpoint);
          const data = response.data["data"];

          const converted = Convert.toADLQuestions(JSON.stringify(data));
          if (converted.categoryScoreDetails) {
            const categoriesMap: ADLCategory[] = converted.categoryScoreDetails
              .filter((val) => typeof val.categoryCode === "string") // Filter out undefined values
              .map((val) => {
                return {
                  category: val.category!,
                  categoryCode: val.categoryCode!,
                };
              });
            setADLCategories(categoriesMap);
            //console.log(categoriesMap);
          }

          setAdlQuestions(converted);

          // reset(convertedBranchList);
          // //console.log("form reset");
          setLoading(false);
        } catch (error) {
          toast.error(error.message);

          console.error("Error fetching Muscle Wasting assessment:", error);

          setLoading(false);
        }
      }
    };
    initializeAssessment();
  }, []);

  const onSubmit = async (dataForm: any) => {
    let data = adlQuestions;
    if (!data) {
      return;
    }

    data.masterAssessmentId = props.masterAssessmentId;
    data.clientId = props.patientId;

    if (data.categoryScoreDetails) {
      data.categoryScoreDetails.forEach((element) => {
        let scoreAnswered = 0;
        let scoreAttempted = 0;
        if (element.questionScore) {
          element.questionScore.forEach((question) => {
            scoreAttempted += question.maxScore ? question.maxScore : 0;

            if (question.answer) {
              if (
                !(question.answer.text === "" && question.answer.score === 0)
              ) {
                // Add the score from the answer object to the total score
                if (question.answer.score)
                  scoreAnswered += question.answer.score;

                //console.log(question);
              }
            }
          });
        }

        // Add totalScore field to the element object
        element.scoreAnswered = scoreAnswered;
        element.scoreAttempted = scoreAttempted;
      });
    }

    if (props.assessmentId !== "000000000000000000000000") {
      try {
        //AdlQuestionSchema.parse(data);
        data.clientId = props.patientId;
        data.masterAssessmentId = props.masterAssessmentId;
        data.entityId = userData?.entity_id ?? "";
        data.entityBranchId = userData?.branch_id ?? "";
        const paramsMap = new Map<string, string>([
          ["id", props.assessmentId!],
        ]);
        const filteredEndpoint = replaceParams(
          endpoints.assessment.updateADLAssessment,
          paramsMap
        );
        const response = await axios.put(filteredEndpoint, data);
        if (response.data.status === 200)
          toast.success(
            response.data.message === "success"
              ? "ADL Assessment updated successfully"
              : response.data.message
          );

        props.postSubmitCallback();
      } catch (error) {
        toast.error(error.message);
        console.error("Error submitting form:", error);
      }
    } else {
      try {
        //AdlQuestionSchema.parse(data);
        data.clientId = props.patientId;
        data.masterAssessmentId = props.masterAssessmentId;
        data.entityId = userData?.entity_id ?? "";
        data.entityBranchId = userData?.branch_id ?? "";
        const paramsMap = new Map<string, string>([
          ["mid", props.masterAssessmentId!],
        ]);
        const filteredEndpoint = replaceParams(
          endpoints.assessment.createADLAssessment,
          paramsMap
        );
        const response = await axios.post(filteredEndpoint, data);
        if (response.data.status === 200)
          toast.success(
            response.data.message === "success"
              ? "ADL Assessment created successfully"
              : response.data.message
          );
        props.postSubmitCallback();
      } catch (error) {
        toast.error(error.message);
        console.error("Error submitting form:", error);
      }
    }
  };

  function comparePlans(
    a: ObservationScoreParameter,
    b: ObservationScoreParameter
  ) {
    if (a.text && b.text) return a.text.toLowerCase() === b.text.toLowerCase();
    else return false;
  }
  return (
    <div className="flex flex-col w-full h-full">
      <div className="flex flex-col md:flex-row w-full justify-between mx-4 my-2">
        <h3 className="text-md leading-6 font-bold text-gray-900">
          ADL Assessment
        </h3>
        <p className="text-sm text-gray-500">
          {props.assessmentId !== "000000000000000000000000" &&
            `Last updated: ${
              lastUpdateAt &&
              moment(new Date(lastUpdateAt)).format("DD-MM-YYYY") !==
                "01-01-0001"
                ? moment(new Date(lastUpdateAt)).format("DD-MM-YYYY")
                : "N/A"
            }`}
        </p>
      </div>

      <hr className="w-full" />

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="w-full">
          <Tab.Group>
            <div className="relative mt-4 w-full">
              <div className="flex flex-col xl:flex-row w-full justify-between">
                <div className="flex flex-col sm:flex-row w-full  justify-end ">
                  <div className="flex w-full justify-end">
                    <Tab.List className="flex space-x-1 rounded-xl xl:ml-2">
                      <Tab
                        key={"Form"}
                        style={{ height: "2.5rem" }}
                        className={({ selected }) =>
                          classNames(
                            "w-full shrink px-4 py-2 mb-4 text-sm text-gray-900 rounded-md border border-gray-300 focus:ring-0 focus:border-primary-500",
                            selected
                              ? "bg-white text-primary-700 shadow border-primary-500"
                              : "text-primary-300"
                          )
                        }
                      >
                        Form
                      </Tab>
                      <Tab
                        key={"Graph"}
                        style={{ height: "2.5rem" }}
                        className={({ selected }) =>
                          classNames(
                            "w-full shrink px-4 py-2 mb-4 text-sm text-gray-900 rounded-md border border-gray-300 focus:ring-0 focus:border-primary-500",
                            selected
                              ? "bg-white text-primary-700 shadow border-primary-500"
                              : "text-primary-300"
                          )
                        }
                      >
                        Graph
                      </Tab>
                    </Tab.List>
                  </div>
                </div>
              </div>

              <Tab.Panels className="mt-2">
                <Tab.Panel
                  className={classNames("rounded-xl bg-white p-3")}
                  key={"Form Panel"}
                >
                  {adlQuestions &&
                    adlQuestions.categoryScoreDetails &&
                    adlQuestions.categoryScoreDetails.map((val, index) => {
                      return (
                        <>
                          <div className="w-full ">
                            <div className="w-full  rounded-2xl bg-white p-2">
                              <Disclosure key={index} defaultOpen={index === 0}>
                                {({ open }) => (
                                  <>
                                    <Disclosure.Button className="flex w-full justify-between items-center rounded-lg bg-primary-100 px-4 py-2 text-left text-sm font-medium text-primary-900 hover:bg-primary-200 focus:outline-none focus-visible:ring focus-visible:ring-primary-500/75">
                                      <span>
                                        {" "}
                                        <div className="flex flex-row justify-between items-center">
                                          <div className="inline-flex items-center justify-center my-2 rounded-lg text-xl px-2 py-1 font-semibold lg:rounded-xl lg:text-xl lg:px-4 lg:py-2 lg:font-medium focus:outline-none   text-black w-full ">
                                            {val.category} ADL
                                          </div>
                                        </div>
                                      </span>
                                      <div className="flex flex-row items-center">
                                        <div className="inline-flex items-center rounded-lg text-lg px-1 py-1 font-normal lg:rounded-xl lg:text-lg lg:px-2 lg:py-2 lg:font-medium focus:outline-none transition ease-in-out duration-150 hover:shadow bg-tertiary-50 hover:bg-tertiary-100 focus:ring-tertiary-500 focus:ring-offset-tertiary-200 text-tertiary-800 shadow-sm border border-tertiary-600  whitespace-nowrap">
                                          Score: {val.scoreAnswered}/
                                          {val.scoreAttempted}
                                        </div>
                                        <ChevronUpIcon
                                          className={`${
                                            open ? "rotate-180 transform" : ""
                                          } h-5 w-5 text-purple-500`}
                                        />
                                      </div>
                                    </Disclosure.Button>
                                    <Disclosure.Panel
                                      className="px-4 pb-2 pt-4 text-sm "
                                      key={index}
                                    >
                                      {val.questionScore &&
                                        val.questionScore.map(
                                          (questionVal, questIndex) => {
                                            return (
                                              <>
                                                <div className="my-8">
                                                  <div className="font-semibold text-lg mb-4">
                                                    {questIndex + 1 + " ]"}{" "}
                                                    {questionVal.question}
                                                  </div>
                                                  {/* <div className="flex flex-col my-4 ml-4 ">
                                          <ul className="grid w-full gap-6 md:grid-cols-2">
                                            {questionVal.observationScores?.map(
                                              (observationVal, obsValIndex) => {
                                                return (
                                                  <>
                                                    <li>
                                                      <input
                                                        {...register(
                                                          `categoryScoreDetails.${index}.questionScore.${questIndex}.answer`
                                                        )}
                                                        name={`categoryScoreDetails.${index}.questionScore.${questIndex}.answer`}
                                                        type="radio"
                                                        id="hosting-small"
                                                        value={JSON.stringify(
                                                          observationVal
                                                        )}
                                                        onChange={(e) => {
                                                          //console.log();
                                                          const parsedValue =
                                                            JSON.parse(
                                                              e.target.value
                                                            );
                                                          setValue(
                                                            `categoryScoreDetails.${index}.questionScore.${questIndex}.answer`,
                                                            parsedValue
                                                          );
                                                        }}
                                                        className="hidden peer"
                                                        required
                                                      />
                                                      <label
                                                        htmlFor="hosting-small"
                                                        className="inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700"
                                                      >
                                                        <div className="block">
                                                          <div className="w-full text-lg font-semibold">
                                                            0-50 MB
                                                          </div>
                                                          <div className="w-full">
                                                            Good for small
                                                            websites
                                                          </div>
                                                        </div>
                                                        <svg
                                                          className="w-5 h-5 ms-3 rtl:rotate-180"
                                                          aria-hidden="true"
                                                          xmlns="http://www.w3.org/2000/svg"
                                                          fill="none"
                                                          viewBox="0 0 14 10"
                                                        >
                                                          <path
                                                            stroke="currentColor"
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                            stroke-width="2"
                                                            d="M1 5h12m0 0L9 1m4 4L9 9"
                                                          />
                                                        </svg>
                                                      </label>
                                                    </li>
                                                  </>
                                                );
                                              }
                                            )}
                                          </ul>
                                        </div> */}

                                                  <RadioGroup
                                                    // key={`categoryScoreDetails.${index}.questionScore.${questIndex}.questionId`}
                                                    // value={getValues(
                                                    //   `categoryScoreDetails.${index}.questionScore.${questIndex}.answer`
                                                    // )}
                                                    by={comparePlans}
                                                    onChange={(e) =>
                                                      setValueFunction(
                                                        index,
                                                        e,
                                                        questIndex
                                                      )
                                                    }
                                                    value={questionVal.answer}
                                                  >
                                                    <RadioGroup.Label className="sr-only">
                                                      Server size
                                                    </RadioGroup.Label>
                                                    <div className="space-y-2">
                                                      {questionVal.observationScores?.map(
                                                        (plan, planIndex) => (
                                                          <RadioGroup.Option
                                                            // key={`categoryScoreDetails.${index}.questionScore.${questIndex}.observationScores.${planIndex}.score`}

                                                            value={plan}
                                                            defaultChecked={
                                                              true
                                                            }
                                                            className={({
                                                              active,
                                                              checked,
                                                            }) =>
                                                              `${
                                                                active
                                                                  ? "border-indigo-600 ring-2 ring-indigo-600 'relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none'"
                                                                  : "border-gray-300 'relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none'"
                                                              }
                  ${checked ? "bg-black-200 text-white" : "bg-white"}
                    relative flex cursor-pointer rounded-lg  px-5 py-4  focus:outline-none`
                                                            }
                                                          >
                                                            {({
                                                              active,
                                                              checked,
                                                            }) => (
                                                              <>
                                                                <div className="flex w-full items-center justify-between">
                                                                  <div className="flex items-center">
                                                                    <div className="text-sm">
                                                                      <RadioGroup.Label
                                                                        as="p"
                                                                        className={`font-medium  ${
                                                                          checked
                                                                            ? "text-primary-500"
                                                                            : "text-gray-900"
                                                                        }`}
                                                                      >
                                                                        {
                                                                          plan.score
                                                                        }{" "}
                                                                        {
                                                                          plan.text
                                                                        }
                                                                      </RadioGroup.Label>
                                                                    </div>
                                                                  </div>
                                                                  {checked && (
                                                                    <div className="shrink-0 text-primary-500">
                                                                      <CheckCircleIcon className="h-6 w-6" />
                                                                    </div>
                                                                  )}
                                                                </div>
                                                              </>
                                                            )}
                                                          </RadioGroup.Option>
                                                        )
                                                      )}
                                                    </div>
                                                  </RadioGroup>
                                                </div>
                                              </>
                                            );
                                          }
                                        )}
                                    </Disclosure.Panel>
                                  </>
                                )}
                              </Disclosure>
                            </div>
                          </div>
                        </>
                      );
                    })}
                  <div className="fixed bottom-0 left-0 right-0 z-10 bg-white shadow-md">
                    <div className="flex flex-row w-full p-4 bg-gray-100 justify-end">
                      <OutlinedButton
                        type="button"
                        onClick={() => {
                          props.postSubmitCallback();
                        }}
                        className="mr-2"
                        variant={ButtonVariant.PRIMARY}
                      >
                        Close
                      </OutlinedButton>
                      {isDisabled ? (
                        <Button
                          type="button"
                          key="EditButton"
                          onClick={() => {
                            setIsDisabled(false);
                          }}
                          variant={ButtonVariant.PRIMARY}
                        >
                          Edit
                        </Button>
                      ) : props.assessmentId !== "000000000000000000000000" ? (
                        <Button
                          type="submit"
                          key="UpdateButton"
                          onClick={() => {}}
                          variant={ButtonVariant.PRIMARY}
                        >
                          Update
                        </Button>
                      ) : (
                        <Button
                          type="submit"
                          key="CreateButton"
                          onClick={() => {}}
                          variant={ButtonVariant.PRIMARY}
                        >
                          Create
                        </Button>
                      )}
                    </div>
                  </div>
                </Tab.Panel>

                <Tab.Panel
                  className={classNames("rounded-xl bg-white p-3")}
                  key={"Graph Panel"}
                >
                  <ADLAssessmentGraph
                    patientId={props.patientId}
                    masterAssessmentId={props.masterAssessmentId}
                    assessmentId={props.assessmentId}
                    adlCategories={adlCategories}
                    postSubmitCallback={function (): void {
                      throw new Error("Function not implemented.");
                    }}
                  ></ADLAssessmentGraph>
                  <div className="fixed bottom-0 left-0 right-0 z-10 bg-white shadow-md">
                    <div className="flex flex-row w-full p-4 bg-gray-100 justify-end">
                      <OutlinedButton
                        type="button"
                        onClick={() => {
                          props.postSubmitCallback();
                        }}
                        className="mr-2"
                        variant={ButtonVariant.PRIMARY}
                      >
                        Close
                      </OutlinedButton>
                    </div>
                  </div>
                </Tab.Panel>
              </Tab.Panels>
            </div>
          </Tab.Group>
        </div>
      </form>
    </div>
  );
}
