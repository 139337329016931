import useAxios from "@routes/hooks/use-axios";
import { endpoints, replaceParams } from "@utils/axios";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import * as z from "zod";
import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { paths } from "@routes/paths";
import { ManagePatientContext } from "@pages/manage-patient/view/ManagePatient";
import Button from "@components/button/button";
import { ButtonVariant } from "@components/button/button";
import { toast } from "react-toastify";
import { OutlinedButton } from "@components/button";
import { Convert } from "./types/Balance";
import { getUserPersistedOnLocalStorage } from "@authentication/context/jwt/utils";
import moment from "moment";

export const BalanceSchema = z.object({
  id: z.string().optional().default(""),
  masterAssessmentId: z.string().optional().default(""),
  clientId: z.string().optional().default(""),
  entityId: z.string().optional().default(""),
  entityBranchId: z.string().optional().default(""),
  singleLegStance: z.string().optional().default(""),
  tandemLegStance: z.string().optional().default(""),
  rhombergTest: z.string().optional().default(""),
  functionalReachTest: z.string().optional().default(""),
  bergsBalanceScaleScore: z.string().optional().default(""),
  tugTestValue: z.string().optional().default(""),
  tinettiPerformanceOrientedMobility: z.string().optional().default(""),
  dynamicGaitIndex: z.string().optional().default(""),
});
export type BalanceSchemaType = z.infer<typeof BalanceSchema>;

type BalanceAssessmentProps = {
  patientId: string;
  masterAssessmentId: string;
  assessmentId: string;
  postSubmitCallback: () => void;
};

export default function BalanceAssessmentComponent(
  props: BalanceAssessmentProps
) {
  const [loading, setLoading] = React.useState(true);
  const [isDisabled, setIsDisabled] = React.useState(false);

  const navigate = useNavigate();

  const axios = useAxios();

  const patientId = useContext(ManagePatientContext);

  const {
    register,
    handleSubmit,
    watch,
    reset,
    control,
    setValue,
    setError,
    formState: { errors },
  } = useForm<BalanceSchemaType>({
    resolver: zodResolver(BalanceSchema),
    defaultValues: { masterAssessmentId: props.masterAssessmentId },
  });

  const userData = getUserPersistedOnLocalStorage();

  const [lastUpdateAt, setLastUpdatedAt] = React.useState("");

  React.useEffect(() => {
    const initializeAssessment = async () => {
      if (props.assessmentId !== "000000000000000000000000") {
        try {
          const paramsMap = new Map<string, string>([
            ["mid", props.masterAssessmentId!],
          ]);
          const filteredEndpoint = replaceParams(
            endpoints.assessment.getBalanceAssessment,
            paramsMap
          );
          const response = await axios.get(filteredEndpoint);
          const data = response.data["data"];
          const converted = Convert.toBalance(JSON.stringify(data));

          reset(converted);

          setLastUpdatedAt(data["audit"]["lastUpdatedAt"]);
          setIsDisabled(true);
          setLoading(false);
        } catch (error) {
          toast.error(error.message);

          console.error("Error fetching Balance assessment:", error);

          setLoading(false);
        }
      }
    };
    initializeAssessment();
  }, []);

  const onSubmit: SubmitHandler<BalanceSchemaType> = async (data: any) => {
    BalanceSchema.parse(data);

    if (props.assessmentId !== "000000000000000000000000") {
      try {
        data.id = props.assessmentId;
        data.clientId = props.patientId;
        data.masterAssessmentId = props.masterAssessmentId;
        data.entityId = userData?.entity_id ?? "";
        data.entityBranchId = userData?.branch_id ?? "";
        const paramsMap = new Map<string, string>([
          ["id", props.assessmentId!],
        ]);
        const filteredEndpoint = replaceParams(
          endpoints.assessment.updateBalanceAssessment,
          paramsMap
        );
        const response = await axios.put(filteredEndpoint, data);
        if (response.data.status === 200)
          toast.success(
            response.data.message === "success"
              ? "Balance Assessment updated successfully"
              : response.data.message
          );

        props.postSubmitCallback();
      } catch (error) {
        toast.error(error.message);

        console.error("Error submitting form:", error);
      }
    } else {
      try {
        data.clientId = props.patientId;
        data.masterAssessmentId = props.masterAssessmentId;
        data.entityId = userData?.entity_id ?? "";
        data.entityBranchId = userData?.branch_id ?? "";
        const paramsMap = new Map<string, string>([
          ["mid", props.masterAssessmentId!],
        ]);
        const filteredEndpoint = replaceParams(
          endpoints.assessment.createBalanceAssessment,
          paramsMap
        );
        const response = await axios.post(filteredEndpoint, data);
        if (response.data.status === 200)
          toast.success(
            response.data.message === "success"
              ? "Balance Assessment created successfully"
              : response.data.message
          );

        props.postSubmitCallback();
      } catch (error) {
        toast.error(error.message);

        console.error("Error submitting form:", error);
      }
    }
  };

  return (
    <div className="flex flex-col w-full h-full">
      <div className="flex flex-col md:flex-row w-full justify-between mx-4 my-2">
        <h3 className="text-md leading-6 font-bold text-gray-900">
          Balance Assessment
        </h3>
        <p className="text-sm text-gray-500">
          {props.assessmentId !== "000000000000000000000000" &&
            `Last updated: ${
              lastUpdateAt &&
              moment(new Date(lastUpdateAt)).format("DD-MM-YYYY") !==
                "01-01-0001"
                ? moment(new Date(lastUpdateAt)).format("DD-MM-YYYY")
                : "N/A"
            }`}
        </p>
      </div>

      <hr className="w-full" />

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col items-stretch">
          <div className="inline-flex items-center justify-center my-2 rounded-lg text-sm px-2 py-1 font-normal lg:rounded-xl lg:text-sm lg:px-4 lg:py-2 lg:font-medium focus:outline-none  bg-tertiary-300 text-white ">
            Static Balance
          </div>
          <div className="mt-4">
            <div>
              <label
                htmlFor="singlelegStance"
                className="block text-sm font-medium text-gray-700"
              >
                Single Leg Stance
              </label>
              <textarea
                {...register("singleLegStance")}
                rows={3}
                value={watch("singleLegStance")}
                placeholder="Single Leg Stance"
                name="singleLegStance"
                className="w-full rounded-lg border-2 border-gray-300 py-2 shadow-sm placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 text-sm leading-6"
                disabled={isDisabled}
              />
            </div>
          </div>
          <div className="mt-4">
            <div>
              <label
                htmlFor="tandemStance"
                className="block text-sm font-medium text-gray-700"
              >
                Tandem Stance
              </label>
              <textarea
                {...register("tandemLegStance")}
                rows={3}
                value={watch("tandemLegStance")}
                placeholder="Tandem Stance"
                name="tandemLegStance"
                className="w-full rounded-lg border-2 border-gray-300 py-2 shadow-sm placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 text-sm leading-6"
                disabled={isDisabled}
              />
            </div>
          </div>
          <div className="mt-4">
            <div>
              <label
                htmlFor="rhombergTest"
                className="block text-sm font-medium text-gray-700"
              >
                Rhomberg Test
              </label>
              <textarea
                {...register("rhombergTest")}
                rows={3}
                value={watch("rhombergTest")}
                placeholder="Rhomberg Test"
                name="rhombergTest"
                className="w-full rounded-lg border-2 border-gray-300 py-2 shadow-sm placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 text-sm leading-6"
                disabled={isDisabled}
              />
            </div>
          </div>
          <div className="mt-4">
            <div>
              <label
                htmlFor="functionalReachTest"
                className="block text-sm font-medium text-gray-700"
              >
                Functional Reach Test
              </label>
              <textarea
                {...register("functionalReachTest")}
                rows={3}
                value={watch("functionalReachTest")}
                placeholder="Functional Reach Test"
                name="functionalReachTest"
                className="w-full rounded-lg border-2 border-gray-300 py-2 shadow-sm placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 text-sm leading-6"
                disabled={isDisabled}
              />
            </div>
          </div>
          <div className="inline-flex items-center justify-center my-2 rounded-lg text-sm px-2 py-1 font-normal lg:rounded-xl lg:text-sm lg:px-4 lg:py-2 lg:font-medium focus:outline-none  bg-tertiary-300 text-white ">
            Dynamic Balance
          </div>
          <div className="mt-4">
            <div>
              <label
                htmlFor="bergBalanceScaleScore"
                className="block text-sm font-medium text-gray-700"
              >
                Bergs Balance Scale Score
              </label>
              <textarea
                {...register("bergsBalanceScaleScore")}
                rows={3}
                value={watch("bergsBalanceScaleScore")}
                placeholder="Bergs Balance Scale Score"
                name="bergsBalanceScaleScore"
                className="w-full rounded-lg border-2 border-gray-300 py-2 shadow-sm placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 text-sm leading-6"
                disabled={isDisabled}
              />
            </div>
          </div>
          <div className="mt-4">
            <div>
              <label
                htmlFor="tugTest"
                className="block text-sm font-medium text-gray-700"
              >
                Tug Test Value
              </label>
              <textarea
                {...register("tugTestValue")}
                rows={3}
                value={watch("tugTestValue")}
                placeholder="Tug Test Value"
                name="tugTestValue"
                className="w-full rounded-lg border-2 border-gray-300 py-2 shadow-sm placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 text-sm leading-6"
                disabled={isDisabled}
              />
            </div>
          </div>
          <div className="mt-4">
            <div>
              <label
                htmlFor="tinettiPerformance"
                className="block text-sm font-medium text-gray-700"
              >
                Tinetti Performance Oriented Mobility
              </label>
              <textarea
                {...register("tinettiPerformanceOrientedMobility")}
                rows={3}
                value={watch("tinettiPerformanceOrientedMobility")}
                placeholder="Tinetti Performance Oriented Mobility"
                name="tinettiPerformanceOrientedMobility"
                className="w-full rounded-lg border-2 border-gray-300 py-2 shadow-sm placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 text-sm leading-6"
                disabled={isDisabled}
              />
            </div>
          </div>
          <div className="mt-4">
            <div>
              <label
                htmlFor="dynamicGaitIndex"
                className="block text-sm font-medium text-gray-700"
              >
                Dynamic Gait Index
              </label>
              <textarea
                {...register("dynamicGaitIndex")}
                rows={3}
                value={watch("dynamicGaitIndex")}
                placeholder="Dynamic Gait Index"
                name="dynamicGaitIndex"
                className="w-full rounded-lg border-2 border-gray-300 py-2 shadow-sm placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 text-sm leading-6"
                disabled={isDisabled}
              />
            </div>
          </div>
          <div className="fixed bottom-0 left-0 right-0 z-10 bg-white shadow-md">
            <div className="flex flex-row w-full p-4 bg-gray-100 justify-end">
              <OutlinedButton
                type="button"
                onClick={() => {
                  props.postSubmitCallback();
                }}
                className="mr-2"
                variant={ButtonVariant.PRIMARY}
              >
                Close
              </OutlinedButton>
              {isDisabled ? (
                <Button
                  type="button"
                  key="EditButton"
                  onClick={() => {
                    setIsDisabled(false);
                  }}
                  variant={ButtonVariant.PRIMARY}
                >
                  Edit
                </Button>
              ) : props.assessmentId !== "000000000000000000000000" ? (
                <Button
                  type="submit"
                  key="UpdateButton"
                  onClick={() => {}}
                  variant={ButtonVariant.PRIMARY}
                >
                  Update
                </Button>
              ) : (
                <Button
                  type="submit"
                  key="CreateButton"
                  onClick={() => {}}
                  variant={ButtonVariant.PRIMARY}
                >
                  Create
                </Button>
              )}
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
