import React, { useEffect, useState } from "react";
import { Pain } from "../type/PainAssessment";
import { Box, Slider } from "@mui/material";
import { InputField } from "@components/hook-form";

type PainType = {
  painAssessment: Pain | null;
  intensity: number;
};

const PainAssessment = (props: PainType) => {
  const [mood, setMood] = useState<string | null>(null);

  const marks = [
    {
      value: 0,
      label: "",
    },
    {
      value: 0.5,
      label: "no pain",
    },
    {
      value: 1,
      label: "",
    },
    {
      value: 2,
      label: "mild",
    },
    {
      value: 3,
      label: "",
    },
    {
      value: 4,
      label: "moderate",
    },
    {
      value: 5,
      label: "",
    },
    {
      value: 6,
      label: "severe",
    },
    {
      value: 7,
      label: "",
    },
    {
      value: 8,
      label: "very severe",
    },
    {
      value: 9,
      label: "",
    },
    {
      value: 9.5,
      label: "worst",
    },
    {
      value: 10,
      label: "",
    },
  ];

  function setMoodForIntensity(intensity: any) {
    if (props.intensity <= 1) {
      setMood("😃");
    } else if (props.intensity > 1 && props.intensity <= 3) {
      setMood("🙂");
    } else if (props.intensity > 3 && props.intensity <= 5) {
      setMood("😑");
    } else if (props.intensity > 5 && props.intensity <= 7) {
      setMood("🙁");
    } else if (props.intensity > 7 && props.intensity <= 9) {
      setMood("😣");
    } else if (props.intensity > 9) {
      setMood("😢");
    }
  }

  useEffect(() => {
    setMoodForIntensity(props.intensity);
  }, []);

  return (
    <div>
      {props.painAssessment !== null ? (
        <div className="">
          <h3 className="mb-4 font-bold text-tertiary-600">Pain Assessment</h3>
          <div className="flex flex-col">
            <table className="table-auto  text-left text-md border-collapse border border-tertiary-500 w-full">
              <tbody>
                {props.painAssessment?.progression === "" ? (
                  <tr></tr>
                ) : (
                  <tr className="border  border-2 border-tertiary-500">
                    <td className="border border-r-2 border-tertiary-500 px-6 py-4 font-medium">
                      Progression
                    </td>
                    <td className="flex justify-center px-6 py-4 ">
                      <p>{props.painAssessment.progression}</p>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>

            <div className="flex flex-col mt-6 border border-2 border-tertiary-500">
              <div className="border px-2 py-4 w-full font-medium">
                Pain Intensity
              </div>
              <div className="flex justify-center px-2 py-8 border-t-2 border-tertiary-500 items-center">
                <Box sx={{ width: 500 }}>
                  <Slider
                    aria-label="Custom marks"
                    value={props.intensity}
                    step={1}
                    min={0}
                    max={10}
                    marks={marks}
                    disabled={true}
                    valueLabelDisplay="on"
                    sx={{
                      color: "transparent",
                      height: 14,
                      "& .MuiSlider-track": {
                        height: 14,
                        background: "transparent",
                      },
                      "& .MuiSlider-rail": {
                        height: 14,
                        background: "linear-gradient(to right, green, red)",
                        maxHeight: 14,
                      },
                      "& .MuiSlider-thumb": {
                        height: 24,
                        width: 24,
                        backgroundColor: "white",
                        border: "2px solid green",
                      },
                    }}
                  />
                </Box>
                <div className="flex flex-row w-14 ml-6">
                  <p style={{ fontSize: "35px" }}>{mood}</p>
                </div>
              </div>
            </div>

            <div className="flex flex-col">
              <table className="table-auto text-left text-md border-collapse border border-2 border-tertiary-500 w-full mt-5">
                <thead className="bg-gray-50 bg-tertiary-100">
                  <tr>
                    <th className="border lg:px-6 px-2 py-4 border-tertiary-500 text-center">
                      Pain Area
                    </th>
                    <th className="border lg:px-6 px-2 py-4 border-tertiary-500 text-center">
                      Left
                    </th>
                    <th className="border lg:px-6 px-2 py-4 border-tertiary-500 text-center">
                      Right
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr key={"Head"} className="border border-tertiary-500">
                    <td className="px-2 py-4 border border-right border-tertiary-500">
                      Head
                    </td>
                    <td className="border px-6 py-4 border-2 text-center border-tertiary-500">
                      <span>
                        {props.painAssessment.headPainLeft === true ? (
                          <img
                            width="28"
                            height="28"
                            src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
                            alt="check-mark-emoji"
                          />
                        ) : (
                          <img
                            width="28"
                            height="28"
                            src="https://img.icons8.com/fluency/48/delete-sign.png"
                            alt="delete-sign"
                          />
                        )}
                      </span>
                    </td>
                    <td className="border px-6 py-4 border-2 text-center border-tertiary-500">
                      <span>
                        {props.painAssessment.headPainRight === true ? (
                          <img
                            width="28"
                            height="28"
                            src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
                            alt="check-mark-emoji"
                          />
                        ) : (
                          <img
                            width="28"
                            height="28"
                            src="https://img.icons8.com/fluency/48/delete-sign.png"
                            alt="delete-sign"
                          />
                        )}
                      </span>
                    </td>
                  </tr>

                  <tr key={"Neck"} className="border border-tertiary-500">
                    <td className="px-2 py-4 border border-right border-tertiary-500">
                      Neck
                    </td>
                    <td className="border px-6 py-4 border-2 text-center border-tertiary-500">
                      <span>
                        {props.painAssessment.neckPainLeft === true ? (
                          <img
                            width="28"
                            height="28"
                            src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
                            alt="check-mark-emoji"
                          />
                        ) : (
                          <img
                            width="28"
                            height="28"
                            src="https://img.icons8.com/fluency/48/delete-sign.png"
                            alt="delete-sign"
                          />
                        )}
                      </span>
                    </td>
                    <td className="border px-6 py-4 border-2 text-center border-tertiary-500">
                      <span>
                        {props.painAssessment.neckPainRight === true ? (
                          <img
                            width="28"
                            height="28"
                            src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
                            alt="check-mark-emoji"
                          />
                        ) : (
                          <img
                            width="28"
                            height="28"
                            src="https://img.icons8.com/fluency/48/delete-sign.png"
                            alt="delete-sign"
                          />
                        )}
                      </span>
                    </td>
                  </tr>

                  <tr key={"Axilla"} className="border border-tertiary-500">
                    <td className="px-2 py-4 border border-right border-tertiary-500">
                      Axilla
                    </td>
                    <td className="border px-6 py-4 border-2 text-center border-tertiary-500">
                      <span>
                        {props.painAssessment.axillaPainLeft === true ? (
                          <img
                            width="28"
                            height="28"
                            src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
                            alt="check-mark-emoji"
                          />
                        ) : (
                          <img
                            width="28"
                            height="28"
                            src="https://img.icons8.com/fluency/48/delete-sign.png"
                            alt="delete-sign"
                          />
                        )}
                      </span>
                    </td>
                    <td className="border px-6 py-4 border-2 text-center border-tertiary-500">
                      <span>
                        {props.painAssessment.axillaPainRight === true ? (
                          <img
                            width="28"
                            height="28"
                            src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
                            alt="check-mark-emoji"
                          />
                        ) : (
                          <img
                            width="28"
                            height="28"
                            src="https://img.icons8.com/fluency/48/delete-sign.png"
                            alt="delete-sign"
                          />
                        )}
                      </span>
                    </td>
                  </tr>

                  <tr key={"Scapula"} className="border border-tertiary-500">
                    <td className="px-2 py-4 border border-right border-tertiary-500">
                      Scapula
                    </td>
                    <td className="border px-6 py-4 border-2 text-center border-tertiary-500">
                      <span>
                        {props.painAssessment.scapulaPainLeft === true ? (
                          <img
                            width="28"
                            height="28"
                            src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
                            alt="check-mark-emoji"
                          />
                        ) : (
                          <img
                            width="28"
                            height="28"
                            src="https://img.icons8.com/fluency/48/delete-sign.png"
                            alt="delete-sign"
                          />
                        )}
                      </span>
                    </td>
                    <td className="border px-6 py-4 border-2 text-center border-tertiary-500">
                      <span>
                        {props.painAssessment.scapulaPainRight === true ? (
                          <img
                            width="28"
                            height="28"
                            src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
                            alt="check-mark-emoji"
                          />
                        ) : (
                          <img
                            width="28"
                            height="28"
                            src="https://img.icons8.com/fluency/48/delete-sign.png"
                            alt="delete-sign"
                          />
                        )}
                      </span>
                    </td>
                  </tr>

                  <tr key={"Shoulder"} className="border border-tertiary-500">
                    <td className="px-2 py-4 border border-right border-tertiary-500">
                      Shoulder
                    </td>
                    <td className="border px-6 py-4 border-2 text-center border-tertiary-500">
                      <span>
                        {props.painAssessment.shoulderPainLeft === true ? (
                          <img
                            width="28"
                            height="28"
                            src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
                            alt="check-mark-emoji"
                          />
                        ) : (
                          <img
                            width="28"
                            height="28"
                            src="https://img.icons8.com/fluency/48/delete-sign.png"
                            alt="delete-sign"
                          />
                        )}
                      </span>
                    </td>
                    <td className="border px-6 py-4 border-2 text-center border-tertiary-500">
                      <span>
                        {props.painAssessment.shoulderPainRight === true ? (
                          <img
                            width="28"
                            height="28"
                            src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
                            alt="check-mark-emoji"
                          />
                        ) : (
                          <img
                            width="28"
                            height="28"
                            src="https://img.icons8.com/fluency/48/delete-sign.png"
                            alt="delete-sign"
                          />
                        )}
                      </span>
                    </td>
                  </tr>

                  <tr key={"Arm"} className="border border-tertiary-500">
                    <td className="px-2 py-4 border border-right border-tertiary-500">
                      Arm
                    </td>
                    <td className="border px-6 py-4 border-2 text-center border-tertiary-500">
                      <span>
                        {props.painAssessment.armPainLeft === true ? (
                          <img
                            width="28"
                            height="28"
                            src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
                            alt="check-mark-emoji"
                          />
                        ) : (
                          <img
                            width="28"
                            height="28"
                            src="https://img.icons8.com/fluency/48/delete-sign.png"
                            alt="delete-sign"
                          />
                        )}
                      </span>
                    </td>
                    <td className="border px-6 py-4 border-2 text-center border-tertiary-500">
                      <span>
                        {props.painAssessment.armPainRight === true ? (
                          <img
                            width="28"
                            height="28"
                            src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
                            alt="check-mark-emoji"
                          />
                        ) : (
                          <img
                            width="28"
                            height="28"
                            src="https://img.icons8.com/fluency/48/delete-sign.png"
                            alt="delete-sign"
                          />
                        )}
                      </span>
                    </td>
                  </tr>

                  <tr key={"Elbow"} className="border border-tertiary-500">
                    <td className="px-2 py-4 border border-right border-tertiary-500">
                      Elbow
                    </td>
                    <td className="border px-6 py-4 border-2 text-center border-tertiary-500">
                      <span>
                        {props.painAssessment.elbowPainLeft === true ? (
                          <img
                            width="28"
                            height="28"
                            src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
                            alt="check-mark-emoji"
                          />
                        ) : (
                          <img
                            width="28"
                            height="28"
                            src="https://img.icons8.com/fluency/48/delete-sign.png"
                            alt="delete-sign"
                          />
                        )}
                      </span>
                    </td>
                    <td className="border px-6 py-4 border-2 text-center border-tertiary-500">
                      <span>
                        {props.painAssessment.elbowPainRight === true ? (
                          <img
                            width="28"
                            height="28"
                            src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
                            alt="check-mark-emoji"
                          />
                        ) : (
                          <img
                            width="28"
                            height="28"
                            src="https://img.icons8.com/fluency/48/delete-sign.png"
                            alt="delete-sign"
                          />
                        )}
                      </span>
                    </td>
                  </tr>

                  <tr key={"Forearm"} className="border border-tertiary-500">
                    <td className="px-2 py-4 border border-right border-tertiary-500">
                      Forearm
                    </td>
                    <td className="border px-6 py-4 border-2 text-center border-tertiary-500">
                      <span>
                        {props.painAssessment.forearmPainLeft === true ? (
                          <img
                            width="28"
                            height="28"
                            src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
                            alt="check-mark-emoji"
                          />
                        ) : (
                          <img
                            width="28"
                            height="28"
                            src="https://img.icons8.com/fluency/48/delete-sign.png"
                            alt="delete-sign"
                          />
                        )}
                      </span>
                    </td>
                    <td className="border px-6 py-4 border-2 text-center border-tertiary-500">
                      <span>
                        {props.painAssessment.forearmPainRight === true ? (
                          <img
                            width="28"
                            height="28"
                            src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
                            alt="check-mark-emoji"
                          />
                        ) : (
                          <img
                            width="28"
                            height="28"
                            src="https://img.icons8.com/fluency/48/delete-sign.png"
                            alt="delete-sign"
                          />
                        )}
                      </span>
                    </td>
                  </tr>

                  <tr key={"Wrist"} className="border border-tertiary-500">
                    <td className="px-2 py-4 border border-right border-tertiary-500">
                      Wrist
                    </td>
                    <td className="border px-6 py-4 border-2 text-center border-tertiary-500">
                      <span>
                        {props.painAssessment.wristPainLeft === true ? (
                          <img
                            width="28"
                            height="28"
                            src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
                            alt="check-mark-emoji"
                          />
                        ) : (
                          <img
                            width="28"
                            height="28"
                            src="https://img.icons8.com/fluency/48/delete-sign.png"
                            alt="delete-sign"
                          />
                        )}
                      </span>
                    </td>
                    <td className="border px-6 py-4 border-2 text-center border-tertiary-500">
                      <span>
                        {props.painAssessment.wristPainRight === true ? (
                          <img
                            width="28"
                            height="28"
                            src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
                            alt="check-mark-emoji"
                          />
                        ) : (
                          <img
                            width="28"
                            height="28"
                            src="https://img.icons8.com/fluency/48/delete-sign.png"
                            alt="delete-sign"
                          />
                        )}
                      </span>
                    </td>
                  </tr>

                  <tr key={"Thumb"} className="border border-tertiary-500">
                    <td className="px-2 py-4 border border-right border-tertiary-500">
                      Wrist
                    </td>
                    <td className="border px-6 py-4 border-2 text-center border-tertiary-500">
                      <span>
                        {props.painAssessment.thumbPainLeft === true ? (
                          <img
                            width="28"
                            height="28"
                            src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
                            alt="check-mark-emoji"
                          />
                        ) : (
                          <img
                            width="28"
                            height="28"
                            src="https://img.icons8.com/fluency/48/delete-sign.png"
                            alt="delete-sign"
                          />
                        )}
                      </span>
                    </td>
                    <td className="border px-6 py-4 border-2 text-center border-tertiary-500">
                      <span>
                        {props.painAssessment.thumbPainRight === true ? (
                          <img
                            width="28"
                            height="28"
                            src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
                            alt="check-mark-emoji"
                          />
                        ) : (
                          <img
                            width="28"
                            height="28"
                            src="https://img.icons8.com/fluency/48/delete-sign.png"
                            alt="delete-sign"
                          />
                        )}
                      </span>
                    </td>
                  </tr>

                  <tr key={"Palm"} className="border border-tertiary-500">
                    <td className="px-2 py-4 border border-right border-tertiary-500">
                      Palm
                    </td>
                    <td className="border px-6 py-4 border-2 text-center border-tertiary-500">
                      <span>
                        {props.painAssessment.palmPainLeft === true ? (
                          <img
                            width="28"
                            height="28"
                            src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
                            alt="check-mark-emoji"
                          />
                        ) : (
                          <img
                            width="28"
                            height="28"
                            src="https://img.icons8.com/fluency/48/delete-sign.png"
                            alt="delete-sign"
                          />
                        )}
                      </span>
                    </td>
                    <td className="border px-6 py-4 border-2 text-center border-tertiary-500">
                      <span>
                        {props.painAssessment.palmPainRight === true ? (
                          <img
                            width="28"
                            height="28"
                            src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
                            alt="check-mark-emoji"
                          />
                        ) : (
                          <img
                            width="28"
                            height="28"
                            src="https://img.icons8.com/fluency/48/delete-sign.png"
                            alt="delete-sign"
                          />
                        )}
                      </span>
                    </td>
                  </tr>

                  <tr key={"Hand"} className="border border-tertiary-500">
                    <td className="px-2 py-4 border border-right border-tertiary-500">
                      Hand
                    </td>
                    <td className="border px-6 py-4 border-2 text-center border-tertiary-500">
                      <span>
                        {props.painAssessment.handPainLeft === true ? (
                          <img
                            width="28"
                            height="28"
                            src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
                            alt="check-mark-emoji"
                          />
                        ) : (
                          <img
                            width="28"
                            height="28"
                            src="https://img.icons8.com/fluency/48/delete-sign.png"
                            alt="delete-sign"
                          />
                        )}
                      </span>
                    </td>
                    <td className="border px-6 py-4 border-2 text-center border-tertiary-500">
                      <span>
                        {props.painAssessment.handPainRight === true ? (
                          <img
                            width="28"
                            height="28"
                            src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
                            alt="check-mark-emoji"
                          />
                        ) : (
                          <img
                            width="28"
                            height="28"
                            src="https://img.icons8.com/fluency/48/delete-sign.png"
                            alt="delete-sign"
                          />
                        )}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
              <table className="table-auto text-left text-md border-collapse border border-2 border-tertiary-500 w-full mt-5">
                <thead className="bg-gray-50 bg-tertiary-100">
                  <tr>
                    <th className="border lg:px-6 px-2 py-4 border-tertiary-500 text-center">
                      Pain Area
                    </th>
                    <th className="border lg:px-6 px-2 py-4 border-tertiary-500 text-center">
                      Left
                    </th>
                    <th className="border lg:px-6 px-2 py-4 border-tertiary-500 text-center">
                      Right
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr key={"Chest"} className="border border-tertiary-500">
                    <td className="px-2 py-4 border border-right border-tertiary-500">
                      Chest
                    </td>
                    <td className="border px-6 py-4 border-2 text-center border-tertiary-500">
                      <span>
                        {props.painAssessment.chestPainLeft === true ? (
                          <img
                            width="28"
                            height="28"
                            src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
                            alt="check-mark-emoji"
                          />
                        ) : (
                          <img
                            width="28"
                            height="28"
                            src="https://img.icons8.com/fluency/48/delete-sign.png"
                            alt="delete-sign"
                          />
                        )}
                      </span>
                    </td>
                    <td className="border px-6 py-4 border-2 text-center border-tertiary-500">
                      <span>
                        {props.painAssessment.chestPainRight === true ? (
                          <img
                            width="28"
                            height="28"
                            src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
                            alt="check-mark-emoji"
                          />
                        ) : (
                          <img
                            width="28"
                            height="28"
                            src="https://img.icons8.com/fluency/48/delete-sign.png"
                            alt="delete-sign"
                          />
                        )}
                      </span>
                    </td>
                  </tr>

                  <tr key={"Back"} className="border border-tertiary-500">
                    <td className="px-2 py-4 border border-right border-tertiary-500">
                      Back
                    </td>
                    <td className="border px-6 py-4 border-2 text-center border-tertiary-500">
                      <span>
                        {props.painAssessment.backPainLeft === true ? (
                          <img
                            width="28"
                            height="28"
                            src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
                            alt="check-mark-emoji"
                          />
                        ) : (
                          <img
                            width="28"
                            height="28"
                            src="https://img.icons8.com/fluency/48/delete-sign.png"
                            alt="delete-sign"
                          />
                        )}
                      </span>
                    </td>
                    <td className="border px-6 py-4 border-2 text-center border-tertiary-500">
                      <span>
                        {props.painAssessment.backPainRight === true ? (
                          <img
                            width="28"
                            height="28"
                            src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
                            alt="check-mark-emoji"
                          />
                        ) : (
                          <img
                            width="28"
                            height="28"
                            src="https://img.icons8.com/fluency/48/delete-sign.png"
                            alt="delete-sign"
                          />
                        )}
                      </span>
                    </td>
                  </tr>

                  <tr key={"Sacrolliac"} className="border border-tertiary-500">
                    <td className="px-2 py-4 border border-right border-tertiary-500">
                      Sacrolliac
                    </td>
                    <td className="border px-6 py-4 border-2 text-center border-tertiary-500">
                      <span>
                        {props.painAssessment.sacrolliacPainLeft === true ? (
                          <img
                            width="28"
                            height="28"
                            src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
                            alt="check-mark-emoji"
                          />
                        ) : (
                          <img
                            width="28"
                            height="28"
                            src="https://img.icons8.com/fluency/48/delete-sign.png"
                            alt="delete-sign"
                          />
                        )}
                      </span>
                    </td>
                    <td className="border px-6 py-4 border-2 text-center border-tertiary-500">
                      <span>
                        {props.painAssessment.sacrolliacPainRight === true ? (
                          <img
                            width="28"
                            height="28"
                            src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
                            alt="check-mark-emoji"
                          />
                        ) : (
                          <img
                            width="28"
                            height="28"
                            src="https://img.icons8.com/fluency/48/delete-sign.png"
                            alt="delete-sign"
                          />
                        )}
                      </span>
                    </td>
                  </tr>

                  <tr key={"Hip"} className="border border-tertiary-500">
                    <td className="px-2 py-4 border border-right border-tertiary-500">
                      Hip
                    </td>
                    <td className="border px-6 py-4 border-2 text-center border-tertiary-500">
                      <span>
                        {props.painAssessment.hipPainLeft === true ? (
                          <img
                            width="28"
                            height="28"
                            src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
                            alt="check-mark-emoji"
                          />
                        ) : (
                          <img
                            width="28"
                            height="28"
                            src="https://img.icons8.com/fluency/48/delete-sign.png"
                            alt="delete-sign"
                          />
                        )}
                      </span>
                    </td>
                    <td className="border px-6 py-4 border-2 text-center border-tertiary-500">
                      <span>
                        {props.painAssessment.hipPainRight === true ? (
                          <img
                            width="28"
                            height="28"
                            src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
                            alt="check-mark-emoji"
                          />
                        ) : (
                          <img
                            width="28"
                            height="28"
                            src="https://img.icons8.com/fluency/48/delete-sign.png"
                            alt="delete-sign"
                          />
                        )}
                      </span>
                    </td>
                  </tr>

                  <tr key={"Thigh"} className="border border-tertiary-500">
                    <td className="px-2 py-4 border border-right border-tertiary-500">
                      Thigh
                    </td>
                    <td className="border px-6 py-4 border-2 text-center border-tertiary-500">
                      <span>
                        {props.painAssessment.thighPainLeft === true ? (
                          <img
                            width="28"
                            height="28"
                            src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
                            alt="check-mark-emoji"
                          />
                        ) : (
                          <img
                            width="28"
                            height="28"
                            src="https://img.icons8.com/fluency/48/delete-sign.png"
                            alt="delete-sign"
                          />
                        )}
                      </span>
                    </td>
                    <td className="border px-6 py-4 border-2 text-center border-tertiary-500">
                      <span>
                        {props.painAssessment.thighPainRight === true ? (
                          <img
                            width="28"
                            height="28"
                            src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
                            alt="check-mark-emoji"
                          />
                        ) : (
                          <img
                            width="28"
                            height="28"
                            src="https://img.icons8.com/fluency/48/delete-sign.png"
                            alt="delete-sign"
                          />
                        )}
                      </span>
                    </td>
                  </tr>

                  <tr key={"I-T-Band"} className="border border-tertiary-500">
                    <td className="px-2 py-4 border border-right border-tertiary-500">
                      I-T Band
                    </td>
                    <td className="border px-6 py-4 border-2 text-center border-tertiary-500">
                      <span>
                        {props.painAssessment.iTBandPainLeft === true ? (
                          <img
                            width="28"
                            height="28"
                            src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
                            alt="check-mark-emoji"
                          />
                        ) : (
                          <img
                            width="28"
                            height="28"
                            src="https://img.icons8.com/fluency/48/delete-sign.png"
                            alt="delete-sign"
                          />
                        )}
                      </span>
                    </td>
                    <td className="border px-6 py-4 border-2 text-center border-tertiary-500">
                      <span>
                        {props.painAssessment.iTBandPainRight === true ? (
                          <img
                            width="28"
                            height="28"
                            src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
                            alt="check-mark-emoji"
                          />
                        ) : (
                          <img
                            width="28"
                            height="28"
                            src="https://img.icons8.com/fluency/48/delete-sign.png"
                            alt="delete-sign"
                          />
                        )}
                      </span>
                    </td>
                  </tr>

                  <tr key={"Knee"} className="border border-tertiary-500">
                    <td className="px-2 py-4 border border-right border-tertiary-500">
                      Knee
                    </td>
                    <td className="border px-6 py-4 border-2 text-center border-tertiary-500">
                      <span>
                        {props.painAssessment.kneePainLeft === true ? (
                          <img
                            width="28"
                            height="28"
                            src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
                            alt="check-mark-emoji"
                          />
                        ) : (
                          <img
                            width="28"
                            height="28"
                            src="https://img.icons8.com/fluency/48/delete-sign.png"
                            alt="delete-sign"
                          />
                        )}
                      </span>
                    </td>
                    <td className="border px-6 py-4 border-2 text-center border-tertiary-500">
                      <span>
                        {props.painAssessment.kneePainRight === true ? (
                          <img
                            width="28"
                            height="28"
                            src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
                            alt="check-mark-emoji"
                          />
                        ) : (
                          <img
                            width="28"
                            height="28"
                            src="https://img.icons8.com/fluency/48/delete-sign.png"
                            alt="delete-sign"
                          />
                        )}
                      </span>
                    </td>
                  </tr>

                  <tr key={"Shin"} className="border border-tertiary-500">
                    <td className="px-2 py-4 border border-right border-tertiary-500">
                      Shin
                    </td>
                    <td className="border px-6 py-4 border-2 text-center border-tertiary-500">
                      <span>
                        {props.painAssessment.shinPainLeft === true ? (
                          <img
                            width="28"
                            height="28"
                            src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
                            alt="check-mark-emoji"
                          />
                        ) : (
                          <img
                            width="28"
                            height="28"
                            src="https://img.icons8.com/fluency/48/delete-sign.png"
                            alt="delete-sign"
                          />
                        )}
                      </span>
                    </td>
                    <td className="border px-6 py-4 border-2 text-center border-tertiary-500">
                      <span>
                        {props.painAssessment.shinPainRight === true ? (
                          <img
                            width="28"
                            height="28"
                            src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
                            alt="check-mark-emoji"
                          />
                        ) : (
                          <img
                            width="28"
                            height="28"
                            src="https://img.icons8.com/fluency/48/delete-sign.png"
                            alt="delete-sign"
                          />
                        )}
                      </span>
                    </td>
                  </tr>

                  <tr key={"Ankle"} className="border border-tertiary-500">
                    <td className="px-2 py-4 border border-right border-tertiary-500">
                      Ankle
                    </td>
                    <td className="border px-6 py-4 border-2 text-center border-tertiary-500">
                      <span>
                        {props.painAssessment.anklePainLeft === true ? (
                          <img
                            width="28"
                            height="28"
                            src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
                            alt="check-mark-emoji"
                          />
                        ) : (
                          <img
                            width="28"
                            height="28"
                            src="https://img.icons8.com/fluency/48/delete-sign.png"
                            alt="delete-sign"
                          />
                        )}
                      </span>
                    </td>
                    <td className="border px-6 py-4 border-2 text-center border-tertiary-500">
                      <span>
                        {props.painAssessment.anklePainRight === true ? (
                          <img
                            width="28"
                            height="28"
                            src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
                            alt="check-mark-emoji"
                          />
                        ) : (
                          <img
                            width="28"
                            height="28"
                            src="https://img.icons8.com/fluency/48/delete-sign.png"
                            alt="delete-sign"
                          />
                        )}
                      </span>
                    </td>
                  </tr>

                  <tr key={"Heel"} className="border border-tertiary-500">
                    <td className="px-2 py-4 border border-right border-tertiary-500">
                      Heel
                    </td>
                    <td className="border px-6 py-4 border-2 text-center border-tertiary-500">
                      <span>
                        {props.painAssessment.heelPainLeft === true ? (
                          <img
                            width="28"
                            height="28"
                            src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
                            alt="check-mark-emoji"
                          />
                        ) : (
                          <img
                            width="28"
                            height="28"
                            src="https://img.icons8.com/fluency/48/delete-sign.png"
                            alt="delete-sign"
                          />
                        )}
                      </span>
                    </td>
                    <td className="border px-6 py-4 border-2 text-center border-tertiary-500">
                      <span>
                        {props.painAssessment.heelPainRight === true ? (
                          <img
                            width="28"
                            height="28"
                            src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
                            alt="check-mark-emoji"
                          />
                        ) : (
                          <img
                            width="28"
                            height="28"
                            src="https://img.icons8.com/fluency/48/delete-sign.png"
                            alt="delete-sign"
                          />
                        )}
                      </span>
                    </td>
                  </tr>

                  <tr key={"GreatToe"} className="border border-tertiary-500">
                    <td className="px-2 py-4 border border-right border-tertiary-500">
                      Great Toe
                    </td>
                    <td className="border px-6 py-4 border-2 text-center border-tertiary-500">
                      <span>
                        {props.painAssessment.greatToePainLeft === true ? (
                          <img
                            width="28"
                            height="28"
                            src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
                            alt="check-mark-emoji"
                          />
                        ) : (
                          <img
                            width="28"
                            height="28"
                            src="https://img.icons8.com/fluency/48/delete-sign.png"
                            alt="delete-sign"
                          />
                        )}
                      </span>
                    </td>
                    <td className="border px-6 py-4 border-2 text-center border-tertiary-500">
                      <span>
                        {props.painAssessment.greatToePainRight === true ? (
                          <img
                            width="28"
                            height="28"
                            src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
                            alt="check-mark-emoji"
                          />
                        ) : (
                          <img
                            width="28"
                            height="28"
                            src="https://img.icons8.com/fluency/48/delete-sign.png"
                            alt="delete-sign"
                          />
                        )}
                      </span>
                    </td>
                  </tr>

                  <tr key={"Foot"} className="border border-tertiary-500">
                    <td className="px-2 py-4 border border-right border-tertiary-500">
                      Foot
                    </td>
                    <td className="border px-6 py-4 border-2 text-center border-tertiary-500">
                      <span>
                        {props.painAssessment.footPainLeft === true ? (
                          <img
                            width="28"
                            height="28"
                            src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
                            alt="check-mark-emoji"
                          />
                        ) : (
                          <img
                            width="28"
                            height="28"
                            src="https://img.icons8.com/fluency/48/delete-sign.png"
                            alt="delete-sign"
                          />
                        )}
                      </span>
                    </td>
                    <td className="border px-8 py-4 border-2 text-center border-tertiary-500">
                      <span>
                        {props.painAssessment.footPainRight === true ? (
                          <img
                            width="28"
                            height="28"
                            src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
                            alt="check-mark-emoji"
                          />
                        ) : (
                          <img
                            width="28"
                            height="28"
                            src="https://img.icons8.com/fluency/48/delete-sign.png"
                            alt="delete-sign"
                          />
                        )}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="grid grid-cols-3 gap-4 text-sm my-4">
              <label
                htmlFor="Stiffness"
                className="block text-lg font-medium text-gray-700 col-span-4"
              >
                Stiffness
              </label>

              <div className="flex flex-row items-center space-x-2">
                <input
                  name="stiffnessMorning"
                  type="checkbox"
                  checked={props.painAssessment.stiffnessMorning}
                  disabled={true}
                />
                <p>Morning</p>
              </div>
              <div className="flex flex-row items-center space-x-2">
                <input
                  name="stiffnessEvening"
                  type="checkbox"
                  checked={props.painAssessment.stiffnessEvening}
                  disabled={true}
                />
                <p>Evening</p>
              </div>
              <div className="flex flex-row items-center space-x-2">
                <input
                  name="stiffnessNight"
                  type="checkbox"
                  checked={props.painAssessment.stiffnessNight}
                  disabled={true}
                />
                <p>Night</p>
              </div>
              <div className="flex flex-row items-center space-x-2">
                <input
                  name="stiffnessWholeDay"
                  type="checkbox"
                  checked={props.painAssessment.stiffnessWholeDay}
                  disabled={true}
                />
                <p>Whole Day</p>
              </div>

              <label
                htmlFor="Sleep"
                className="block text-lg font-medium text-gray-700 col-span-4"
              >
                Sleep
              </label>

              <div className="flex flex-row items-center space-x-2">
                <input
                  name="sleepDisturbed"
                  type="checkbox"
                  checked={props.painAssessment.sleepDisturbed}
                  disabled={true}
                />
                <p>Disturbed</p>
              </div>
              <div className="flex flex-row col-span-3 items-center space-x-2">
                <input
                  name="sleepNormal"
                  type="checkbox"
                  checked={props.painAssessment.sleepNormal}
                  disabled={true}
                />
                <p>Normal</p>
              </div>

              <label
                htmlFor="Travelling"
                className="block text-lg font-medium text-gray-700 col-span-4"
              >
                Travelling
              </label>

              <div className="flex flex-row items-center space-x-2">
                <input
                  name="travellingTwoWheeler"
                  type="checkbox"
                  checked={props.painAssessment.travellingTwoWheeler}
                  disabled={true}
                />
                <p>2 Wheeler</p>
              </div>
              <div className="flex flex-row items-center space-x-2">
                <input
                  name="travellingFourWheeler"
                  type="checkbox"
                  checked={props.painAssessment.travellingTwoWheeler}
                  disabled={true}
                />
                <p>4 Wheeler</p>
              </div>
              <div className="flex flex-row col-span-2 items-center space-x-2">
                <input
                  name="travellingPublicTransport"
                  type="checkbox"
                  checked={props.painAssessment.travellingPublicTransport}
                  disabled={true}
                />
                <p>Public Transport</p>
              </div>
            </div>

            <div className="flex flex-row items-center mt-4">
              <p className="text-md mr-2 font-medium">
                Daily Kilometers Travelled -{" "}
              </p>
              <p>{props.painAssessment.dailyTravelKm ?? "0"}</p>
            </div>

            <table className="table-auto  text-left text-md border-collapse border border-2 border-tertiary-500 w-full mt-5">
              <thead className="bg-gray-50 bg-tertiary-100">
                <tr>
                  <th className="border lg:px-6 px-6 py-4 border-2 border-tertiary-500">
                    Pain Type
                  </th>
                  <th className="border lg:px-6 px-6 py-4 border-2 border-tertiary-500">
                    Left{" "}
                  </th>
                  <th className="border lg:px-6 px-6 py-4 border-2 border-tertiary-500 ">
                    Right{" "}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr key={"ConstantIntermittent"}>
                  <td className="border px-6 py-4 border-2 border-tertiary-500 font-medium">
                    Constant Intermittent
                  </td>
                  <td className="border px-6 py-4 border-2 text-center border-tertiary-500">
                    <span>
                      {props.painAssessment.constantIntermittentLeft ===
                      true ? (
                        <img
                          width="28"
                          height="28"
                          src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
                          alt="check-mark-emoji"
                        />
                      ) : (
                        <img
                          width="28"
                          height="28"
                          src="https://img.icons8.com/fluency/48/delete-sign.png"
                          alt="delete-sign"
                        />
                      )}
                    </span>
                  </td>
                  <td className="border px-6 py-4 border-2 text-center border-tertiary-500">
                    {props.painAssessment.constantIntermittentRight === true ? (
                      <img
                        width="28"
                        height="28"
                        src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
                        alt="check-mark-emoji"
                      />
                    ) : (
                      <img
                        width="28"
                        height="28"
                        src="https://img.icons8.com/fluency/48/delete-sign.png"
                        alt="delete-sign"
                      />
                    )}
                  </td>
                </tr>
                <tr key={"Sharp"}>
                  <td className="border px-6 py-4 border-2 border-tertiary-500 font-medium">
                    Sharp
                  </td>
                  <td className="border px-6 py-4 border-2 text-center border-tertiary-500">
                    {props.painAssessment.sharpLeft === true ? (
                      <img
                        width="28"
                        height="28"
                        src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
                        alt="check-mark-emoji"
                      />
                    ) : (
                      <img
                        width="28"
                        height="28"
                        src="https://img.icons8.com/fluency/48/delete-sign.png"
                        alt="delete-sign"
                      />
                    )}
                  </td>
                  <td className="border px-6 py-4 border-2 text-center border-tertiary-500">
                    {props.painAssessment.sharpRight === true ? (
                      <img
                        width="28"
                        height="28"
                        src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
                        alt="check-mark-emoji"
                      />
                    ) : (
                      <img
                        width="28"
                        height="28"
                        src="https://img.icons8.com/fluency/48/delete-sign.png"
                        alt="delete-sign"
                      />
                    )}
                  </td>
                </tr>
                <tr key={"Shooting"}>
                  <td className="border px-6 py-4 border-2 border-tertiary-500 font-medium">
                    Shooting
                  </td>
                  <td className="border px-6 py-4 border-2 text-center border-tertiary-500">
                    {props.painAssessment.shootingLeft === true ? (
                      <img
                        width="28"
                        height="28"
                        src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
                        alt="check-mark-emoji"
                      />
                    ) : (
                      <img
                        width="28"
                        height="28"
                        src="https://img.icons8.com/fluency/48/delete-sign.png"
                        alt="delete-sign"
                      />
                    )}
                  </td>
                  <td className="border px-6 py-4 border-2 text-center border-tertiary-500">
                    {props.painAssessment.shootingRight === true ? (
                      <img
                        width="28"
                        height="28"
                        src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
                        alt="check-mark-emoji"
                      />
                    ) : (
                      <img
                        width="28"
                        height="28"
                        src="https://img.icons8.com/fluency/48/delete-sign.png"
                        alt="delete-sign"
                      />
                    )}
                  </td>
                </tr>
                <tr key={"Burning"}>
                  <td className="border px-6 py-4 border-2 border-tertiary-500 font-medium">
                    Burning
                  </td>
                  <td className="border px-6 py-4 border-2 text-center border-tertiary-500">
                    {props.painAssessment.burningLeft === true ? (
                      <img
                        width="28"
                        height="28"
                        src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
                        alt="check-mark-emoji"
                      />
                    ) : (
                      <img
                        width="28"
                        height="28"
                        src="https://img.icons8.com/fluency/48/delete-sign.png"
                        alt="delete-sign"
                      />
                    )}
                  </td>
                  <td className="border px-6 py-4 border-2 text-center border-tertiary-500">
                    {props.painAssessment.burningRight === true ? (
                      <img
                        width="28"
                        height="28"
                        src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
                        alt="check-mark-emoji"
                      />
                    ) : (
                      <img
                        width="28"
                        height="28"
                        src="https://img.icons8.com/fluency/48/delete-sign.png"
                        alt="delete-sign"
                      />
                    )}
                  </td>
                </tr>
                <tr key={"Throbbing"}>
                  <td className="border px-6 py-4 border-2 border-tertiary-500 font-medium">
                    Throbbing
                  </td>
                  <td className="border px-6 py-4 border-2 text-center border-tertiary-500">
                    {props.painAssessment.throbbingLeft === true ? (
                      <img
                        width="28"
                        height="28"
                        src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
                        alt="check-mark-emoji"
                      />
                    ) : (
                      <img
                        width="28"
                        height="28"
                        src="https://img.icons8.com/fluency/48/delete-sign.png"
                        alt="delete-sign"
                      />
                    )}
                  </td>
                  <td className="border px-6 py-4 border-2 text-center border-tertiary-500">
                    {props.painAssessment.throbbingRight === true ? (
                      <img
                        width="28"
                        height="28"
                        src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
                        alt="check-mark-emoji"
                      />
                    ) : (
                      <img
                        width="28"
                        height="28"
                        src="https://img.icons8.com/fluency/48/delete-sign.png"
                        alt="delete-sign"
                      />
                    )}
                  </td>
                </tr>
                <tr key={"Numbness"}>
                  <td className="border px-6 py-4 border-2 border-tertiary-500 font-medium">
                    Numbness
                  </td>
                  <td className="border px-6 py-4 border-2 justify-center border-tertiary-500">
                    {props.painAssessment.numbnessLeft === true ? (
                      <img
                        width="28"
                        height="28"
                        src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
                        alt="check-mark-emoji"
                      />
                    ) : (
                      <img
                        width="28"
                        height="28"
                        src="https://img.icons8.com/fluency/48/delete-sign.png"
                        alt="delete-sign"
                      />
                    )}
                  </td>
                  <td className="border px-6 py-4 border-2 border-tertiary-500">
                    {props.painAssessment.numbnessRight === true ? (
                      <img
                        width="28"
                        height="28"
                        src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
                        alt="check-mark-emoji"
                      />
                    ) : (
                      <img
                        width="28"
                        height="28"
                        src="https://img.icons8.com/fluency/48/delete-sign.png"
                        alt="delete-sign"
                      />
                    )}
                  </td>
                </tr>
                <tr key={"PinsAndNeedles"}>
                  <td className="border px-6 py-4 border-2 border-tertiary-500 font-medium">
                    Pins And Needles
                  </td>
                  <td className="border px-6 py-4 border-2 text-center border-tertiary-500">
                    {props.painAssessment.pinsAndNeedlesLeft === true ? (
                      <img
                        width="28"
                        height="28"
                        src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
                        alt="check-mark-emoji"
                      />
                    ) : (
                      <img
                        width="28"
                        height="28"
                        src="https://img.icons8.com/fluency/48/delete-sign.png"
                        alt="delete-sign"
                      />
                    )}
                  </td>
                  <td className="border px-6 py-4 border-2 text-center border-tertiary-500">
                    {props.painAssessment.pinsAndNeedlesRight === true ? (
                      <img
                        width="28"
                        height="28"
                        src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
                        alt="check-mark-emoji"
                      />
                    ) : (
                      <img
                        width="28"
                        height="28"
                        src="https://img.icons8.com/fluency/48/delete-sign.png"
                        alt="delete-sign"
                      />
                    )}
                  </td>
                </tr>
              </tbody>
            </table>

            <div className="mt-4">
              {props.painAssessment.activityLimitation !== "" ? (
                <div>
                  <div className="flex flex-row justify-between w-full mt-4 bg-tertiary-100 px-2 py-1 font-medium">
                    Activity Limitation
                  </div>
                  <article className="text-wrap">
                    <p className="px-2 py-1 ">
                      {props.painAssessment.activityLimitation}
                    </p>
                  </article>
                </div>
              ) : (
                <div></div>
              )}

              {props.painAssessment.aggravatingFactors !== "" ? (
                <div className="mt-2">
                  <div className="flex flex-row justify-between w-full mt-4 bg-tertiary-100 px-2 py-1 font-medium">
                    Aggravating Factors
                  </div>
                  <p className="px-2 py-1 text-wrap">
                    {props.painAssessment.aggravatingFactors}
                  </p>
                </div>
              ) : (
                <div></div>
              )}

              {props.painAssessment.relievingFactors !== "" ? (
                <div className="mt-2">
                  <div className="flex flex-row justify-between w-full mt-4 bg-tertiary-100 px-2 py-1 font-medium">
                    Relieving Factors
                  </div>
                  <p className="px-2 py-1 flex text-wrap">
                    {props.painAssessment.relievingFactors}
                  </p>
                </div>
              ) : (
                <div></div>
              )}
            </div>
          </div>
          <hr className=" my-12 border-tertiary-500"></hr>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default PainAssessment;
